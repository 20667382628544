
@import "modules/responsive-type.scss";

$primary: #ff7d7d; /* MAIN COLOR */
$secondary: #fcc083; /* SECONDARY COLOR */
$trm: #333; /* Trim Color */
$blk: #000; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fff; 
$slv: #d2d2d2; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

@import url('https://fonts.googleapis.com/css?family=Amatic+SC|Jura');

// font-family: 'Amatic SC', cursive;
// font-family: 'Jura', sans-serif;

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

a, p, ol, ul {
	font-family: 'Jura', sans-serif;
}

h1 {
    font-family: 'Amatic SC', cursive;
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {
    font-family: 'Amatic SC', cursive;
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
    font-family: 'Amatic SC', cursive;
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
	display:none;
}

nav {
	z-index: 1000;
	width: 100% !important;
}

.row {
	margin-left: 0px;
	margin-right: 0px;
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

.navbar-default {
	background-color: $primary;
    border: none;
	// background: url('../img/navbar-bg.jpg');
	// background-repeat: repeat-x;
	// border-radius: 0px;
	// border: 0px;
	// border-bottom: 5px solid $secondary;
	// width: 100% !important;
}

.navbar-default .navbar-collapse {
	border: none;
	
	@media (max-width: 1024px) {
		background-color: transparent;
	}

	@media (max-width: 767px) {
		background-color: $primary;
		}
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 25px;
		display: flex;
		align-items: center;
		color: $wht;
		outline: none;
		font-size: 16px;
		// border-right: 2px solid $secondary;

		@media (max-width: 767px) {
		    height: 25px;
		    display: inline-block;
			border: 0px;
		}

		&:focus, &:visited {
	    	background: transparent;
	    	color: $wht;
		}
		
		&:hover {
			color: $trm;
		}
	}
}

.navbar-right {
	margin-top: 40px;
	font-size: 2em;

	@media (max-width: 1024px) {
		font-size: 1.5em;
		margin-top: 30px;
	}

	@media (max-width: 767px) {
		font-size: 1.5em;
		margin-top: 0px;
	}
}
 
.navbar-toggle {
    margin: 15px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 20px;
	}
}

.top-pad {
    padding: 3em 0em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em 0em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 767px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.logo {
	width: 100%;
	max-width: 225px;
	margin-left: 10px;
	padding: 5px;

	@media (max-width: 1024px) {
		width: 100%;
		max-width: 175px;
		margin-left: 0px;
		// padding: 10px;
	}  

	@media (max-width: 767px) {
		width: 100%;
		max-width: 155px;
		margin-left: 0px;
		// padding: 10px;
	}  
}

.nav .nav-divider {
	margin: 0px 0px;
}

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 40px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    background-color: $primary;
    border-color: $wht;
	color: $wht;
	padding: 10px 20px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 12px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
	 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.btn-download:hover {
    background-color: $slv;
    color: $blk;

}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
	margin-top: 50px;


	@media (max-width: 767px) {

		margin-top: 10px;
	}



	@media (max-width: 1024px) {

		margin-top: 10px;
	}
}


#signUpForm {
    padding: 0px 10px !important;
}

.shadowthis {
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.75);
}

.shadowthat {
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25), 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
}

.borderthis{
    border: 10px solid #fff;
    border-radius: 10px;
}


/* ========== HEADER ============== */

.header {
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0%;
    padding: 200px 0px;

    @media (max-width: 767px) {
        background-position: 50% 0%; 
        padding: 300px 0px 20px;
    }

    @media (max-width: 568px) {
    	background-position: 35% 0%;
        padding: 350px 0px 20px;
    }

    @media (max-width: 320px) {
        padding: 300px 0px 10px;
    }
	
	img {
		display: block;
		margin: 0 auto;		 
	}
}

		 
/* ========== MINIFORM AREA ============== */

.miniform {
	background-color: $slv;
	padding: 50px;

	@media (max-width: 1280px) {
		padding: 35px 0px;
		text-align: center;
	}

	img {
		width: 100%;
		display: block;
		margin: 0 auto;
		max-width: 650px;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
				   
		@media (max-width: 1024px) {
			width: 100%;
			display: block;
			margin: 0 auto;
			max-width: 500px;
			margin-top: 25px;
			margin-bottom: 25px;
		}
	}

	h1 {
		color: $primary;
		text-shadow: 1px 1px $trm;
		padding-bottom: 50px;

		@media (max-width: 767px) {
			padding-bottom: 10px;
			// font-size: 2em;
		}
	}

	p {
		font-size: 1.65em;
		letter-spacing: 1px;
		line-height: 50px;
		color: $primary;
		padding: 10px;
	}
}

/* ========== BENEFITS ============== */

.benefits {
	background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/lower-banner-1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0%;
    padding: 300px 0px;
    text-align: center;

	@media (max-width: 767px) {
	   padding: 150px 0px;
	   background-position: 35% 0%;
	}

	@media (max-width: 568px) {
	   padding: 100px 0px;
	   background-position: 35% 0%;
	}

	@media (max-width: 320px) {
	   padding: 50px 0px;
	   background-position: 35% 0%;
	}

	h1 {
		font-size: 3.5em;
		color: $wht;
		text-shadow: 1px 1px $primary;


		@media (max-width: 1024px) {
			font-size: 3em;
		}

		@media (max-width: 767px) {
			font-size: 2em;
		}
	}

	p {
		font-size: 1.65em;
		letter-spacing: 1px;
		line-height: 40px;
		padding: 10px;
		color: $wht;
			 
		@media (max-width: 767px) {
			font-size: 1.25em;
			line-height: 23px;
		}
	}

	img {
		width: 100%;
		max-width: 550px;
		display: block;
		margin: 0 auto;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
				
		@media (max-width: 767px) {
			padding-top: 0px
		}
	
		@media (max-width: 1024px) {
			margin-top: 25px;
		}

		@media (max-width: 1199px) {
			margin-top: 60px
		}
	}
}

/* ============ CTA ============ */

.cta {
	background-color: $slv;
	padding: 25px 0px;
	  
	  @media (max-width: 767px) {
		  padding: 0px;
	  }

	h1 {
		font-size: 3.5em;
		color: $primary;
		text-shadow: 1px 1px $trm;


		@media (max-width: 1024px) {
			font-size: 3em;
		}

		@media (max-width: 767px) {
			font-size: 2em;
		}
	}

	p {
		font-size: 1.65em;
		letter-spacing: 1px;
		line-height: 40px;
		padding: 10px;
		color: $blk;
			 
		@media (max-width: 767px) {
			font-size: 1.25em;
			line-height: 23px;
		}
	}
}


/* ========== LOWER CTA ============== */

.lower-cta {
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/lower-cta-banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0%;
    padding: 150px 0px;
    text-align: center;

	@media (max-width: 767px) {
	   padding: 150px 0px;
	   background-position: 50% 0%;
	}

	h1 {
		font-size: 3.5em;
		color: $wht;
		text-shadow: 1px 1px $primary;


		@media (max-width: 1024px) {
			font-size: 3em;
		}

		@media (max-width: 767px) {
			font-size: 2em;
		}
	}

	p {
		font-size: 1.65em;
		letter-spacing: 1px;
		line-height: 40px;
		padding: 10px;
		color: $wht;
			 
		@media (max-width: 767px) {
			font-size: 1.25em;
			line-height: 23px;
		}
	}

	img {
		width: 100%;
		max-width: 550px;
		display: block;
		margin: 0 auto;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
				
		@media (max-width: 767px) {
			padding-top: 0px
		}

		@media (max-width: 1024px) {
			margin-top: 25px;
		}

		@media (max-width: 1199px) {
			margin-top: 60px
		}
	}
}